@import '~bloko/common/styles/media/_screen';

.wrapper {
    display: flex;
    align-items: center;

    @media @screen-lt-s {
        gap: 4px;
        align-items: unset;
        flex-direction: column;
    }
}

.left-column {
    flex: 1 1 auto;
}

.right-column {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.overflow-text {
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    width: 100%;
    overflow: hidden;
    overflow-wrap: anywhere;
}
