@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/rounded-corner-base';
@import '@hh.ru/magritte-ui/tokens';

.label {
    position: relative;
    border-radius: @rounded-corner-default;
    display: inline-block;
    padding: 2px 8px;
    vertical-align: middle;
    color: @color-gray-80;
    white-space: nowrap;
}

.label_with-circle {
    padding-left: 24px;
}

.label-circle {
    position: absolute;
    top: calc(50% - 4px);
    left: 8px;
    width: 8px;
    height: 8px;
    background-color: @color-white;
    border-radius: 50%;
}

.label_light-gray {
    background-color: @magritte-color-background-secondary;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    color: @magritte-color-component-tag-text-neutral;
}

.label-circle_light-green {
    background-color: @color-green-60;
}

.label_green {
    background-color: @color-green-20;
}

.label_light-green {
    background-color: @color-green-10;
}

.label_light-green-redesign {
    background-color: @magritte-color-background-positive-secondary;
    // stylelint-disable-next-line declaration-property-value-allowed-list
    color: @magritte-color-component-tag-text-positive;
}

.label_orange {
    background-color: @color-orange-20;
}

.label_light-orange {
    background-color: @color-orange-10;
}

.label_yellow {
    background-color: @color-yellow-20;
}

.label_light-yellow {
    background-color: @color-yellow-10;
}

.label_light-blue {
    background-color: @color-blue-10;
}

.label_gray {
    background-color: @color-gray-20;
}

.label_violet {
    background-color: @color-violet-50;
    color: @color-white;
}

.label_light-violet {
    background-color: @color-violet-10;
}

.label_light-red {
    background-color: @color-red-10;
}

.label-size-medium {
    padding: 4px 8px;
    border-radius: @magritte-static-border-radius-200;
}
