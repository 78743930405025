@import '~bloko/common/styles/_column-variables';
@import '~bloko/common/styles/spacing-base';
@import '@hh.ru/magritte-ui/breakpoints';

.actions-wrapper {
    display: none;

    .screen-gt-xs({
        display: block;
    });
}

.cards-scroller {
    display: flex;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: @spacing-base @column-padding @spacing-base * 3;
    margin: 0 -@column-padding;

    &::after {
        content: '';
        display: block;
    }

    .screen-gt-xs({
        overflow: initial;
        padding: 0;
        margin: 0;

        &::after {
            display: none;
        }
    });
}

.cards-wrapper {
    display: flex;
    gap: 12px;
    flex: 1;

    .screen-gt-xs({
        flex-wrap: wrap;
    });

    .screen-gt-s({
        flex-direction: column;
        gap: 16px;
        width: 100%;
    });
}
