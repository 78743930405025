@import '@hh.ru/magritte-ui/tokens';

.content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
}

.banner {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 12px;
    padding: 24px 24px 0;
    background-color: @magritte-color-background-accent-secondary;
}

.banner-art {
    flex-grow: 1;
    background-image: url('vacancyStat.svg');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 100px;
}
