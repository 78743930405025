@import '~bloko/common/styles/_abstract-control';
@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/_column-variables';
@import '../../../../styles/globals/blocks/vacancy-serp/vacancy-serp-variables';
@import '@hh.ru/magritte-ui/tokens';

.letter {
    display: flex;
    min-height: 0;
    background: @magritte-color-background-warning-secondary;
    padding: 20px;
    box-sizing: border-box;
    border-radius: @rounded-corner-default;
    overflow: hidden;
}

.letter_hidden {
    display: none;
}

.letter_vacancy-body {
    margin: 0 -15px 20px;

    @media @screen-gt-xs {
        margin: 0 0 20px;
    }
}

.letter_search {
    padding-left: 15px;
    padding-right: 15px;

    @media @screen-gt-xs {
        min-height: auto;
    }
}

.letter-wrapper {
    display: flex;
    box-sizing: border-box;
    flex-grow: 1;
}

.letter-icon {
    width: 60px;
    height: 60px;
    background: url('letter-icon.svg') no-repeat;
    margin-right: 25px;
    display: none;
    flex-shrink: 0;

    @media @screen-gt-xs {
        display: block;
    }
}

.letter-wrapper_narrow {
    .letter-icon {
        display: none;
    }
}

.letter-content {
    overflow: hidden;
    max-width: 480px;
    width: 100%;
    .abstract-control-focus-visible-clip-container();
}

.letter-content_success {
    display: block;
}

.letter-activity {
    @media @screen-gt-s {
        width: 210px;
        margin-left: auto;
        padding-left: 15px;
    }
}

.letter-activity-spacing-s {
    display: none;

    @media @screen-s {
        display: block;
    }
}

.letter-content-container {
    width: 100%;
}

.separator {
    width: 100%;
    border-bottom: 1px solid @color-gray-30;
}

.top-separator {
    border-bottom: 1px solid @color-gray-30;
    margin: 0 -@column-padding - @serp-item-border-width * 2;

    @media @screen-gt-xs {
        margin: 0 -@serp-item-padding-desktop - @serp-item-border-width * 2;
    }
}

.letter-form {
    height: 100%;
}

.letter-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    background: @magritte-color-component-card-background-warning;
    border: 1px solid @magritte-color-icon-warning-secondary;
    box-sizing: border-box;
    min-width: 64px;
    height: 64px;
}
