@import '@hh.ru/magritte-ui/breakpoints';
@import '@hh.ru/magritte-ui/tokens';

.container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    .screen-gt-xs({
      display: grid;
      gap: 24px;
      grid-template-columns: 1fr 1fr;
    });

    .screen-gt-s({
      grid-template-columns: 1fr 1fr 1fr;
    });

    .screen-gt-m({
      grid-template-columns: 1fr 1fr 1fr 1fr;
    });
}

.banner-card {
    border-radius: @magritte-static-border-radius-400;
    overflow: hidden;
}
