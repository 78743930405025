.logo {
    background-image: url('dream-job-reviews-logo.svg');
    height: 14px;
    width: 16px;
}

.title {
    display: flex;
    gap: 8px;
    align-items: center;
}
