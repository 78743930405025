@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';

.wrapper {
    @media @screen-gt-xs {
        max-width: 515px;
    }
}

.container {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media @screen-gt-xs {
        flex-direction: row;
    }
}

.input-wrapper {
    flex: 1;
}

.button-wrapper {
    flex: 0;
}

.agreement {
    color: @color-gray-60;
}

.wrapper-magritte {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.wrapper-form {
    width: 100%;
}

.title {
    @media @screen-gt-m {
        max-width: 600px;
    }
}

.illustration {
    margin-left: 100px;
    margin-top: -36px;
}

.box {
    display: flex;
    justify-content: space-between;
}
