@import '@hh.ru/magritte-ui/breakpoints';

.container {
    width: 100%;

    .screen-gt-s({
        display: flex;
    });
}

.card-list {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .screen-gt-s({
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 24px;
    });
}

.card-content {
    display: flex;
    justify-content: flex-start;

    .screen-gt-s({
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    });
}

.icon-wrapper {
    flex-shrink: 1;
    margin-top: -3px;

    .screen-gt-s({
        margin-top: 0;
    });
}
