.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    // tempexp_PORTFOLIO-26478_start
    img {
        object-fit: contain;
    }

    // tempexp_PORTFOLIO-26478_end
}

.applied-button {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: center;
}

.info {
    padding: 12px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.response-buttons {
    display: flex;
    justify-content: space-between;
    gap: 8px;
}
