@import '../../bloko/common/styles/colors';
@import '../../bloko/common/styles/rounded-corner-base';

@shadow-block-padding: 16px;
@shadow-block-desktop-padding: 28px;

.shadow-block () {
    box-shadow: 0 2px 12px 0 fade(@color-gray-80, 10%);
    border-radius: @rounded-corner-default;
}

.shadow-block-clear () {
    box-shadow: none;
    border-radius: 0;
}
