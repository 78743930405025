@import '@hh.ru/magritte-ui/breakpoints';

@gap: 24px;
@gap-s: 16px;
@gap-xs: 12px;

.faq-wrapper {
    gap: @gap-xs;
    display: flex;
    flex-direction: row;

    .screen-gt-xs({
      gap: @gap-s;
      grid-template-columns: 50% 50%;
      display: grid;
    });

    .screen-gt-s({
      grid-template-columns: 33% 33% 33%;
    });

    .screen-gt-m({
      grid-template-columns: 25% 25% 25% 25%;
    });
}

.faq-wrapper-vertical {
    display: flex;
    gap: @gap-xs;
    flex-direction: column;
}

.faq-card-wrapper {
    width: 272px;
    height: 232px;
    flex-shrink: 0;

    .screen-gt-xs({
      width: auto;
    });
}

.faq-card-wrapper-bottomsheet {
    height: auto;
    width: 100%;
}

.faq-card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.faq-employer-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.spacer {
    flex-grow: 1;
}
