@import '@hh.ru/magritte-ui/breakpoints';

.wrapper {
    .screen-gt-xs({
        max-width: 370px;
    });
}

.container {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    .screen-gt-s({
        flex-wrap: nowrap;
    });
}

.submit {
    flex-shrink: 1;
    min-width: 100%;

    .screen-gt-s({
        min-width: auto;
    });
}

.content {
    flex-grow: 1;
    position: relative;

    .screen-gt-m({
        padding-right: 300px;
        min-width: auto;
    });
}
