@import '~bloko/common/styles/spacing-base';
@import '~bloko/common/styles/colors';
@import '@hh.ru/magritte-ui/breakpoints';
@import '@hh.ru/magritte-ui/tokens';

.premium-container {
    position: relative;
    white-space: nowrap;
    margin-left: @spacing-base * 2;
    top: 2px;
    --magritte-ui-icon-color-override: @magritte-color-base-red-60;

    svg [fill] {
        /* stylelint-disable-next-line declaration-no-important */
        fill: @magritte-color-base-red-60 !important;
    }

    &::before {
        content: '\200B';
    }

    .screen-gt-xs({
        top: 1px;
    });
}
