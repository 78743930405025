@import '~bloko/common/styles/colors';
@import '@hh.ru/magritte-ui/tokens';

.response-streak-success-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: @color-blue-10;
}

.response-streak-success {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    color: @color-blue-60;
}

.response-streak-number {
    color: @magritte-color-text-accent;
}

.response-streak-success-badge {
    width: 20px;
    height: 20px;
    background-color: @color-blue-60;
}

.response-streak-success-badge-1 {
    mask: url('../../Applicant/LoopCounter/images/badge-1.svg') center center no-repeat;
}

.response-streak-success-badge-2 {
    mask: url('../../Applicant/LoopCounter/images/badge-2.svg') center center no-repeat;
}

.response-streak-success-badge-3 {
    mask: url('../../Applicant/LoopCounter/images/badge-3.svg') center center no-repeat;
}

.response-streak-success-badge-4 {
    mask: url('../../Applicant/LoopCounter/images/badge-4.svg') center center no-repeat;
}

.response-streak-success-badge-5 {
    mask: url('../../Applicant/LoopCounter/images/badge-5.svg') center center no-repeat;
}

.response-streak-success-badge-6 {
    mask: url('../../Applicant/LoopCounter/images/badge-6.svg') center center no-repeat;
}

.response-streak-success-badge-7 {
    mask: url('../../Applicant/LoopCounter/images/badge-7.svg') center center no-repeat;
}
