@import '@hh.ru/magritte-ui/tokens';

@container-spacing: 12px;
@transition-duration: @magritte-semantic-animation-ease-in-out-400-duration;
@transition-function: @magritte-semantic-animation-ease-in-out-400-timing-function;

.container,
.rows-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.collapsible-container {
    position: relative;
    padding: @container-spacing;
}

.collapsible-cards-container {
    position: relative;
}

.collapsible-cards {
    position: absolute;
    top: 0;
    left: -@container-spacing;
    width: calc(100% + 2 * @container-spacing);
    transition: max-height @transition-function @transition-duration;
    overflow: hidden;
    max-height: 0;
    background: @magritte-color-background-body;
    border-radius: 24px;
}

.border {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    border: 1px solid @magritte-color-stroke-neutral;
    border-radius: 24px;
    pointer-events: none;
    transition: height @transition-function @transition-duration;
}

.rows-container {
    transform: scale(1);
    transform-origin: top center;
    transition-timing-function: @transition-function;
    transition-duration: @transition-duration;
    transition-property: transform, filter;
}

.rows-container-hidden {
    transform: scale(0.75);
    filter: blur(4px);
}

.button-text {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
