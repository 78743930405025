@import '@hh.ru/magritte-ui/breakpoints';

.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    .screen-gt-s({
        grid-template-columns: 1fr 1fr 1fr;
    });

    .screen-gt-m({
        grid-template-columns: 1fr 1fr 1fr 1fr;
    });
}

.container-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.title {
    display: flex;
    align-items: center;
    gap: 8px;
}
