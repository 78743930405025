// tempexp_27495_file
@import '~bloko/common/styles/media/_screen';

.wrapper {
    @media @screen-gt-xs {
        width: 321px;
    }
}

.header {
    display: flex;
    justify-content: flex-end;
}
