@import '@hh.ru/magritte-ui/breakpoints';

@gap: 24px;
@gap-s: 16px;
@gap-xs: 12px;

.wrapper {
    display: flex;
    gap: @gap-xs;
    .screen-gt-xs({
        gap: @gap-s;
        flex-wrap: wrap;
    });
    .screen-gt-s({
        gap: @gap;
    });
}

.wrapper-vertical {
    display: flex;
    gap: @gap-xs;
    flex-direction: column;
}

.item {
    width: 270px;
    flex-shrink: 0;
    .screen-gt-xs({
        width: 100%;
    });
    .screen-gt-s({
        width: calc((100% - (2 * @gap)) / 3);
    });
    .screen-gt-l({
        width: calc((100% - (3 * @gap)) / 4);
    });
}
