@import '@hh.ru/magritte-ui/tokens';

.row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.row-text {
    display: flex;
    align-items: center;
    gap: 4px;
}

.badge-container {
    display: flex;
    position: relative;
    top: -1px;
}

.card-container-fixed-height {
    height: 96px;
}

// TODO как стиль добавят в UI kit убрать костыль HH-246780
.card-container-warning {
    --magritte-card-background-override: @magritte-color-background-warning;
}

.card-container-with-hover-drop {
    position: relative;
}

.card-container-with-hover-drop::after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    height: 100%;
    width: 20px;
}
