@import '~bloko/common/styles/colors';
@import '@hh.ru/magritte-ui/tokens';

.fake-magritte-primary-text {
    color: @magritte-color-text-primary;
}

:global(.archived-vacancy-card) .fake-magritte-primary-text {
    color: @color-gray-50;
}
