.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    img {
        object-fit: contain;
    }
}
