.container {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 12px;
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
