@import '@hh.ru/magritte-ui/breakpoints';
@import '@hh.ru/magritte-ui/tokens';

.subtitle {
    display: flex;
    flex-direction: column;
    word-break: break-all;
    overflow: hidden;

    .screen-gt-xs({
        flex-direction: row;
    });
}

.section {
    display: flex;

    .screen-gt-xs({
        position: relative;
        min-width: 150px;
        margin-left: 10px;

        &:first-child {
            margin-left: 0;
        }

        &::before {
            content: '';
            position: absolute;
            left: -6px;
            top: 9px;
            width: 2px;
            height: 2px;
            background-color: @magritte-color-text-secondary;
            border-radius: 100%;
        }
    });
}

.growing {
    .screen-gt-xs({
        flex-grow: 1;
        flex-shrink: 0;
        min-width: initial;
    });
}
