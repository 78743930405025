@import '@hh.ru/magritte-ui/breakpoints';

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.inner {
    .screen-gt-s({
        padding: 12px;
    });
}
