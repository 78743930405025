@import '@hh.ru/magritte-ui/breakpoints';

.banner {
    width: 100%;

    @media @screen-magritte-gt-s {
        width: 370px;
        margin-left: auto;
    }
}

.fixed {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 24px;
    right: 0;
    left: 0;
    z-index: 1000;
}

.background {
    position: absolute;
    z-index: 1;
    top: -26px;
    right: -16px;
    width: 100%;
    height: 100%;
    background-image: url('assets/button.png');
    background-repeat: no-repeat;
    background-size: 136px 146px;
    background-position: top 0 right 0;

    @media @screen-magritte-gt-s {
        top: unset;
        bottom: 12px;
        right: -20px;
        background-size: 160px 172px;
        background-position: bottom 0 right 0;
    }
}

.text {
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 12px 116px 0 12px;
    box-sizing: border-box;

    @media @screen-magritte-gt-s {
        width: 290px;
        padding: 12px 12px 0;
    }
}

.buttons {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}
