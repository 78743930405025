.container {
    display: flex;
}

.image {
    background-image: url('./img/img.png');
    background-size: 297px 251px;
    width: 297px;
    min-width: 297px;
    height: 251px;
    margin-left: 48px;
}
