@import '~bloko/common/styles/colors';

.banner {
    background: transparent;
    border-left: 0;
    border-right: 0;
    width: 100%;
    text-align: left;
    color: @color-blue-50;
}

.banner-container {
    width: 100%;
    text-align: left;
}

.banner-app-icon {
    display: inline-block;
    width: 48px;
    height: 48px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('./img/hh_applicant_app.svg');
}
