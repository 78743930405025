@import '@hh.ru/magritte-ui/breakpoints';

.wrapper {
    display: flex;
    flex-flow: wrap;
    width: 100%;
}

.support {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media @screen-magritte-gt-s {
        flex-direction: row;
    }
}
