@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/_layers';

@banner-screen-height-m: 600px;
@banner-screen-height-l: 680px;

@keyframes move {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}

.fullsize() {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.container {
    .fullsize();

    position: fixed;
    z-index: @topmost;
    overflow: auto;

    @media @screen-gt-xs {
        display: none;
    }
}

.overlay {
    .fullsize();

    position: absolute;
    background: fade(@color-special-absolute-black, 50);
}

.wrapper {
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    background: @color-white;
    display: flex;
    flex-direction: column;
    animation: move 0.5s ease;
}

.close {
    position: absolute;
    top: 20px;
    right: 20px;
}

.content {
    margin-top: auto;
    padding-top: 40px;
    text-align: center;
}

.logo {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url('../../styles/images/logos/apps/hh_ios_applicant_app.svg?v=20042022');
    height: 52px;
    width: 52px;
    margin: 0 auto 20px;

    :global(.theme-zarplata) & {
        background-image: url('../../styles/images/logos/apps/zp_android_applicant_app.svg?v=20240305');
    }
}

.title {
    margin-bottom: 24px;
    white-space: pre-wrap;
}

.main {
    background-size: 485px;
    padding-bottom: 32px;
}
