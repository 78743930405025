@import '~bloko/common/styles/_abstract-modal';
@import '~bloko/common/styles/colors';

.modal {
    flex-grow: 1;
    overflow-y: auto;
    margin: -@modal-vertical-padding-xs -@modal-padding-xs;
    -webkit-overflow-scrolling: touch;
}

.modal-content {
    overflow-x: hidden;
    padding: @modal-vertical-padding-xs @modal-padding-xs;
}
