@import '@hh.ru/magritte-ui/breakpoints';

.container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 0;
}

.item {
    box-sizing: border-box;
    width: 100%;

    .screen-gt-xs({
    width: 50%;
    padding-right: 20px;
  });
}
