@import '@hh.ru/magritte-ui/breakpoints';

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.wrapper_zp {
    flex-direction: row;
}

.social-wrapper {
    display: flex;
    justify-content: center;
    gap: 8px;
    .screen-gt-s({
        flex-direction: column;
    });
}

.social-wrapper_zp {
    .screen-gt-s({
        flex-direction: row;
    });
}

.links-bottom {
    display: block;
    .screen-gt-s({
        display: none;
    });
}

.top-section {
    display: none;
    .screen-gt-s({
        display: flex;
        justify-content: space-between;
        margin-bottom: auto;
    });
}

.qr-image {
    display: block;
    width: 112px;
    height: 112px;
}
