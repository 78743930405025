@import '@hh.ru/magritte-ui/breakpoints';
@import '../../styles/globals/variables/color-variables';

.wrapper {
    .screen-gt-m({
        border-right: 1px solid @separator-color;
    });
}

.list-wrapper {
    flex: 1;
    .screen-gt-s({
        column-count: 2;
        column-gap: 24px;
    });
    .screen-gt-m({
        column-count: 3;
    });
}
