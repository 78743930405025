.placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 345px;
    height: 288px;
    margin: 0 auto;
    padding: 0 16px;
}
