@import '@hh.ru/magritte-ui/tokens';

.info-section {
    overflow: hidden;
    clear: both;
}

.vacancy-logo-container {
    float: right;
}

.company-name-badges-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: @magritte-static-space-100;

    a,
    span {
        display: flex;
    }
}
