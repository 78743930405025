.bg-image {
    position: absolute;
    background-repeat: no-repeat;
    background-image: url('./bg.png');
    background-size: contain;
    width: 250px;
    height: 262px;
    bottom: -116px;
    left: 50%;
    transform: translateX(-50%);
}
