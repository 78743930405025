@import '~@hh.ru/magritte-ui/breakpoints';

.driver-licenses-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 12px;

    .screen-gt-s({
        grid-template-columns: 1fr 1fr 1fr;
    });
}

.has-vehicle-wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}
