@import '~bloko/common/styles/media/_screen';

.wrapper {
    @media @screen-gt-xs {
        max-width: 420px;
    }
}

.wrapper-magritte {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media @screen-gt-m {
        max-width: 500px;
    }
}

.container-form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    @media @screen-gt-s {
        flex-direction: row;
    }
}

.illustration {
    margin-left: 80px;
    margin-top: -32px;
}

.box {
    display: flex;
    justify-content: space-between;
}

.content {
    flex-grow: 1;
}
