@import '@hh.ru/magritte-ui/breakpoints';

.cell-content:hover {
    .screen-gt-m({
        .chevron {
            display: none;
        }

        .remove {
            display: flex;
        }
    });
}

.chevron {
    display: none;

    .screen-gt-m({
        display: flex;
    });
}

.remove {
    display: flex;

    .screen-gt-m({
        display: none;
    });
}
