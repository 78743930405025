@import '@hh.ru/magritte-ui/breakpoints';

.header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-buttons-wrapper {
    flex: 1;
}

.header-map-button {
    display: none;
    .screen-gt-xs({
        display: block;
    });
}

.header-map-button-experiment {
    display: none;
    .screen-gt-xs({
        display: block;
    });
}

.header-search-link {
    align-self: flex-start;
    display: block;
    white-space: nowrap;

    .screen-gt-xs({
        display: none;
    });
}

.header-search-link-experiment {
    float: right;
    margin-left: 16px;
    .screen-gt-xs({
        display: none;
    });
}
