@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-ui/breakpoints';

.list-stretched {
    .screen-gt-s({
        display: flex;
        flex-direction: column;
        flex-grow: 1000;

        .vacancy {
            display: flex;
            flex-grow: 1;
            align-items: center;
        }
    });
}

.vacancy {
    display: flex;
    flex-direction: column;
    padding: 12px;
    border-bottom: 1px solid @magritte-color-stroke-neutral;

    .screen-gt-s({
        flex-direction: row;
        justify-content: space-between;
        padding: 18px;
        padding-left: 24px;
    });
}

.vacancy-content {
    padding: 12px;

    .screen-gt-s({
        padding: 0;
    });
}

.vacancy-button-wrapper {
    display: flex;
    align-items: center;
    width: 100%;

    .screen-gt-s({
        flex-shrink: 0;
        width: 166px;
    });
}

.vacancy-description {
    display: flex;
    flex-wrap: wrap;
    column-gap: 12px;
    row-gap: 4px;
    overflow: hidden;
}

.vacancy-description-item {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        left: -7px;
        top: 9px;
        width: 2px;
        height: 2px;
        background-color: @magritte-color-text-secondary;
        border-radius: 100%;
    }
}
