@import '~bloko/common/styles/colors';
@import '@hh.ru/magritte-ui/tokens';

.employer-online {
    color: @color-green-50;
}

.employer-online-magritte {
    align-self: center;
    display: inline-block;
    height: 16px;
    width: 44px;
    background: url('online.svg');
}
