@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-ui/breakpoints';

.count-new {
    color: @magritte-color-text-positive;
}

.list-wrapper {
    display: flex;
    .screen-gt-s({
        flex-direction: column;
        gap: 24px;
    });
}

.list-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}
