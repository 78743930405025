@import '@hh.ru/magritte-ui/breakpoints';

.container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    row-gap: 12px;
    column-gap: 24px;

    .screen-gt-s({
      flex-direction: row;
    });
}
