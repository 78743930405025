@import '~bloko/common/styles/colors';
@import '@hh.ru/magritte-ui/breakpoints';

.wrapper {
    display: flex;
    border: 1px solid @color-gray-20;
    border-radius: 8px;
    flex-direction: column;
    overflow: hidden;

    .screen-gt-s({
        flex-direction: row;
    });
}

.wrapper-steps {
    flex: 1;
    padding: 20px 16px;
    background: radial-gradient(circle at top right, @color-blue-10 0, @color-white 130%);

    .screen-gt-xs({
        padding: 40px;
    });
}

.wrapper-content {
    flex: 1;
    padding: 20px 16px;

    .screen-gt-xs({
        padding: 40px;
        display: flex;
        align-items: center;
    });
}

.logo {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../../../../styles/images/logos/hh.ru.svg?v=20072023');
    width: 32px;
    height: 32px;

    .screen-gt-xs({
        width: 40px;
        height: 40px;
    });
}

.logo_jobs-tut-by {
    background-image: url('../../../../styles/images/logos/rabota.by.svg?v=29012024');
    width: 110px;
    height: 32px;

    .screen-gt-xs({
        width: 122px;
        height: 36px;
    });
}

.logo_zp {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../../../../styles/images/logos/hr.zarplata.ru.svg?v=15012024');
    height: 32px;
    width: 180px;

    .screen-gt-xs({
        width: 180px;
        height: 40px;
    });
}

.step-description {
    padding: 16px;
    background: @color-white;
    border-radius: 0 16px 16px;
    display: inline-block;
}

.content {
    flex: 1;
}

.container {
    .screen-gt-s({
        display: flex;
        justify-content: space-between;
    });
}
