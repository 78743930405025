@import '@hh.ru/magritte-ui/breakpoints';

.vacancies-placeholder {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 12px;
}

.vacancies-placeholder-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 12px;
    padding-bottom: 0;
    text-align: center;
}

.vacancies-placeholder-image {
    width: 100%;
    height: 108px;
    background: url('../assets/placeholder.svg') center bottom / contain no-repeat;

    .screen-gt-xs({
        height: 168px;
    });
}

.vacancies-placeholder-image_zp {
    background-image: url('../assets/placeholder_zp.svg');

    .screen-gt-xs({
        height: 177px;
    });
}

.spacer {
    flex-grow: 1;
}
