@import '~bloko/common/styles/colors';
@import '@hh.ru/magritte-ui/breakpoints';

.wrapper {
    .screen-gt-xs({
        max-width: 515px;

    });
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .screen-gt-xs({
        flex-direction: row;
    });
}

.input-wrapper {
    flex: 1;
}

.button-wrapper {
    flex: 0;
}

.illustration {
    right: 0;
    bottom: 0;
    position: absolute;
}

.wrapper-magritte {
    display: flex;
    justify-content: space-between;
}

.title {
    width: 100%;
}

.content {
    flex-grow: 1;
    position: relative;
}

.box {
    display: flex;
    position: relative;
    justify-content: space-between;
    .screen-gt-s({
        padding-right: 330px;
    });
    .screen-gt-m({
        padding-right: 604px;
    });
}
