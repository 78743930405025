@import '@hh.ru/magritte-ui/tokens';

.tabs-wrapper {
    padding: 0 24px;
    border-bottom: 1px solid @magritte-color-stroke-neutral;

    // Убрать после добавления баджа в секондари-табы магрита
    /* stylelint-disable max-nesting-depth */
    button[data-qa='tab-has-news'] {
        span[data-qa='tab-postfix'] {
            position: relative;
            overflow: visible;

            &::after {
                position: absolute;
                content: '';
                top: -5px;
                right: -11px;
                width: 6px;
                height: 6px;
                border-radius: 100%;
                background-color: @magritte-color-background-negative;
            }
        }
    }
    /* stylelint-enable max-nesting-depth */
}

.widget-tab-content {
    display: flex;
    flex-direction: column;
    height: 290px;
    border-bottom-left-radius: @magritte-static-border-radius-600;
    border-bottom-right-radius: @magritte-static-border-radius-600;
    overflow-y: scroll;
    overflow-x: hidden;

    // Убрать после выработки общего решения
    // HH-250616
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: color-mix(in srgb, @magritte-color-background-constant-secondary 32%, transparent);
        border-radius: 24px;
    }
}
