@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-ui/breakpoints';

.card-content-wrapper {
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    gap: 12px;
    color: @magritte-color-text-secondary;
    height: 100%;
    .screen-gt-xs({
        flex-direction: column;
        flex: 1;
        justify-content: space-between;
    });
    .screen-gt-s({
        flex-direction: row-reverse;
        height: auto;
    });
}

.card-content-wrapper-container {
    position: relative;
    display: flex;
    flex: 1;
    min-width: 220px;
    width: 240px;
    .screen-gt-xs({
        width: auto;
    });
}

.card-description {
    display: none;

    .screen-gt-xs({
        display: block; 
    });
}

.card-icon-wrapper {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    flex-grow: 0;
}

.card-text-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    .screen-gt-s({
        flex: initial;
    });
}

.card-action {
    .screen-gt-s({
        margin: 0 -12px -12px -12px;
    });
}
