@import '@hh.ru/magritte-ui/tokens';

.cell-wrapper {
    padding: 24px;
    border-bottom: 1px solid @magritte-color-stroke-neutral;

    &:last-child {
        border-bottom: 0;
    }
}
