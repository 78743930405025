.icon {
    width: 24px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
}

.clickme {
    background-image: url('icons/clickme.svg');
}

.hrlink {
    width: 59px;
    height: 24px;
    background-image: url('icons/hrlink.svg');
}

.hhbusiness {
    width: 92px;
    height: 24px;
    background-image: url('icons/hhbusiness.svg');
}

.skillaz {
    width: 90px;
    height: 18px;
    background-image: url('icons/skillaz.svg');
}

.talantix {
    width: 80px;
    height: 28px;
    background-image: url('icons/talantix.png');
}

.setka {
    background-image: url('icons/setka.png');
}

.rabotaby {
    width: 20px;
    height: 20px;
    background-image: url('icons/rabotaby.svg');
}
