@import '@hh.ru/design-tokens/build/less/variables';
@import 'colors';
@import 'color-utils';
@import 'text-base';
@import 'rounded-corner-base';

@control-background-color: @color-white;
@control-color: @color-gray-80;
@control-border-width: 1px;
@control-border-color: @color-gray-40;
@control-border-color-invalid: @color-red-50;
@control-border-color-invalid-hover: @color-red-60;
@control-border-color-hover: @color-gray-50;
@control-border-color-focus: @color-blue-60;
@control-border: @control-border-width solid @control-border-color;
@control-box-shadow-focus: 0 1px fade(@color-gray-80, 20%);
@control-box-shadow-focus-visible-width: 4px;
@control-box-shadow-focus-visible: 0 0 0 @control-box-shadow-focus-visible-width fade(@color-blue-50, 50%);
@control-box-shadow-focus-visible-inset: inset 0 0 0 @control-box-shadow-focus-visible-width fade(@color-blue-50, 50%);
@control-box-shadow-active: inset 0 2px 1px fade(@color-gray-80, 17.35%);
@control-height-scale-small: 32px;
@control-height-scale-medium: 40px;
@control-height-scale-large: 48px;
@control-opacity-disabled: 0.5;
@control-padding-vertical: 10px;
@control-padding-horizontal: 12px;
@control-padding-horizontal-large: 12px;
@control-icon-size: 16px;
@control-icon-margin: 9px;
@control-icon-margin-large: 15px;
@control-border-radius: @rounded-corner-default;

@control-background-color-complementary: @color-gray-10;
@control-background-color-complementary-active: @color-gray-20;

@control-font-color-complementary: @color-gray-80;

.abstract-control-focus-visible() {
    box-shadow: @control-box-shadow-focus-visible;
    outline: none;
    z-index: 1;
}

.abstract-control-focus-visible-inset() {
    box-shadow: @control-box-shadow-focus-visible-inset;
    outline: none;
    z-index: 1;
}

.abstract-control-focus-visible-clip-container() {
    padding: @control-box-shadow-focus-visible-width;
    margin: -@control-box-shadow-focus-visible-width;
}

/* stylelint-disable selector-nested-pattern */
.abstract-control() {
    .bloko-text-medium();

    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    height: @control-height-scale-medium;
    padding: 0 @control-padding-horizontal;
    margin: 0;

    border-radius: @control-border-radius;
    border: @control-border;

    font-family: @_font-family;
    color: @control-color;

    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-tap-highlight-color: transparent;

    &:hover {
        ._move-color-hsv-value(border-color, @control-border-color, -20);
    }

    &_invalid {
        border-color: @control-border-color-invalid;
        box-shadow: none;
    }

    &_invalid:hover {
        border-color: @control-border-color-invalid-hover;
    }

    &:disabled {
        opacity: @control-opacity-disabled;
        border-color: @control-border-color;
    }

    &:-moz-ui-invalid {
        box-shadow: none;
    }

    &:focus {
        border-color: @control-border-color-focus;
        outline: none;
    }

    &:global(.focus-visible) {
        .abstract-control-focus-visible();
    }
}
/* stylelint-enable */

.abstract-control-group-item-elements() {
    // Сдвигаем внутренние элементы для того чтобы убрать двойные рамки
    .bloko-button,
    .bloko-input-text,
    .bloko-select,
    .bloko-custom-select__select {
        position: relative;
        min-width: calc(~'100% + 1px');
        margin-left: -1px;
        border-radius: 0;
    }

    &:first-child {
        .bloko-button,
        .bloko-select,
        .bloko-custom-select__select,
        .bloko-input-text {
            min-width: 0;
            margin-left: 0;
        }

        .bloko-button,
        .bloko-select,
        .bloko-custom-select__select {
            border-top-left-radius: @control-border-radius;
            border-bottom-left-radius: @control-border-radius;
        }

        .bloko-input-text {
            border-top-left-radius: @bloko-border-radius-data-entry;
            border-bottom-left-radius: @bloko-border-radius-data-entry;
        }
    }

    &:last-child {
        .bloko-button,
        .bloko-select,
        .bloko-custom-select__select {
            border-top-right-radius: @control-border-radius;
            border-bottom-right-radius: @control-border-radius;
        }

        .bloko-input-text {
            border-top-right-radius: @bloko-border-radius-data-entry;
            border-bottom-right-radius: @bloko-border-radius-data-entry;
        }
    }
}
