@import '~bloko/common/styles/_layers';
@import '~bloko/common/styles/spacing-base';
@import '@hh.ru/design-tokens/build/less/base-variables';
@import '@hh.ru/magritte-ui/tokens';

@gap: @spacing-base * 3;
@gap-horizontal-xs: @spacing-base * 2.5;
@gap-vertical-xs: @spacing-base * 1.5;

.set-content-before(@gap-vertical, @gap-horizontal) {
    position: absolute;
    content: '';
    cursor: pointer;
    z-index: @above-content-almost;
    top: -@gap-vertical;
    left: -@gap-horizontal;
    bottom: -@gap-vertical;
    right: -@gap-horizontal;
}

.branded-snippet {
    position: relative;
    overflow: hidden;
    box-shadow: @magritte-shadow-level-1-x @magritte-shadow-level-1-y @magritte-shadow-level-1-blur
        @magritte-shadow-level-1-spread @magritte-shadow-level-1-color;
    margin-bottom: @spacing-base * 4;
    display: flex;
}

.branded-snippet-xs {
    border-radius: 12px;
    flex-direction: column-reverse;
    gap: @gap-vertical-xs;
    padding: @gap-vertical-xs @gap-horizontal-xs @gap-horizontal-xs;
}

.branded-snippet-desk {
    border-radius: @magritte-static-border-radius-400;
    flex-direction: row;
    gap: @gap;
    padding: @gap;
}

.branded-snippet-magritte {
    border-radius: @magritte-static-border-radius-600;
    margin-bottom: 0;
}

.branded-snippet-content {
    position: relative;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    z-index: 1;
    flex: 1;
}

.branded-snippet-logo {
    position: absolute;
}

.branded-snippet-decoration {
    position: relative;
}

.branded-snippet-decoration-xs {
    width: auto;
    height: 72px;
}

.branded-snippet-decoration-xs::before {
    .set-content-before(@gap-vertical-xs, @gap-horizontal-xs);
}

.branded-snippet-decoration-desk {
    height: 100%;
    width: 188px;
}

.branded-snippet-decoration-desk::before {
    .set-content-before(@gap, @gap);
}

.branded-snippet-logo-wrapper {
    display: flex;
    align-items: center;
}

.branded-snippet-logo-wrapper-alone {
    height: 100%;
    justify-content: center;
}

.branded-snippet-logo-wrapper-not-alone-xs {
    height: 100%;
    justify-content: flex-start;
    padding-left: @gap-horizontal-xs;
    padding-right: @gap-horizontal-xs;
}

.branded-snippet-logo-wrapper-not-alone-desk {
    padding: 0;
    height: 50%;
    justify-content: center;
}

.branded-snippet-picture {
    position: absolute;
    right: 0;
}

.branded-snippet-picture-hidden {
    opacity: 0;
}

.branded-snippet-picture-skeleton::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* stylelint-disable declaration-property-value-allowed-list */
    z-index: -1;
    background-color: @magritte-color-component-skeleton-background-content;
    /* stylelint-enable declaration-property-value-allowed-list */
    animation: @magritte-semantic-animation-ease-in-out-tiger-800-duration
        @magritte-semantic-animation-ease-in-out-tiger-800-timing-function branded-snippet-skeleton infinite;
}

@keyframes branded-snippet-skeleton {
    0% {
        opacity: 25%;
    }

    50% {
        opacity: 100%;
    }

    100% {
        opacity: 25%;
    }
}

.branded-snippet-picture-xs {
    top: 0;
    transform: none;
}

.branded-snippet-picture-desk {
    // Картинка центрируется по высоте 3/4 размера контейнера с логотипом
    // с учетом внешних отступов контейнера относительно обложки
    top: calc(@gap + (100% - (2 * @gap)) * 3 / 4);
    transform: translate(0, -50%);
}
