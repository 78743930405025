.bg-image {
    position: absolute;
    background-repeat: no-repeat;
    background-image: url('./bg.png');
    background-size: contain;
    width: 507px;
    height: 262px;
    bottom: -56px;
    left: calc(50% - 16px);
    transform: translateX(-50%);
}
