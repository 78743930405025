@import '@hh.ru/magritte-ui/breakpoints';

.container {
    display: block;
}

.card-container {
    height: auto;

    .screen-gt-xs({
        height: 136px;
    });
}

.card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.title {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    align-items: center;
}

.chevron-wrap {
    flex-shrink: 0;
    display: flex;
    align-items: center;
}
