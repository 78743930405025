@import '~bloko/common/styles/colors';

.work-in-city-wrapper {
    display: inline-block;
}

.work-in-city {
    color: @color-gray-80;
}

.work-in-city-paragraph {
    margin-top: 11px;
}

.work-in-city-paragraph-toggling {
    display: none;
}

.work-in-city-expanded .work-in-city-paragraph-show-more {
    display: none;
}

.work-in-city-expanded .work-in-city-paragraph-toggling {
    display: block;
}
