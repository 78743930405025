// tempexp_27495_file
@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';
@import '@hh.ru/magritte-ui/breakpoints';

.input-wrapper {
    flex: 1;
}

.button-wrapper {
    flex: 0;
}

.agreement {
    color: @color-gray-60;
}

.field {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media @screen-magritte-gt-s {
        flex-direction: row;
    }
}

.input {
    width: 100%;
}

.submit {
    width: 100%;

    @media @screen-magritte-gt-s {
        width: auto;
    }
}
