.card-container {
    overflow: hidden;
    border-radius: 24px;
}

.container {
    display: flex;
    flex-direction: column;
    height: 272px;
    position: relative;
}

.content {
    flex-basis: 100%;
    padding: 12px;
}

.image {
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
}

.image.clickme {
    background-image: url('./images/clickme.png');
    width: 382px;
    height: 138px;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
}

.image.clickme-secondary {
    background-image: url('./images/clickme_secondary.png');
    width: 365px;
    height: 206px;
    bottom: -40px;
    left: calc(50% - 40px);
    transform: translateX(-50%);
}

.image.start-link {
    background-image: url('./images/startlink.png');
    left: 50%;
    transform: translateX(-50%);
    width: 145px;
    height: 152px;
    bottom: 20px;
}

.image.hr-link {
    background-image: url('./images/hrlink.png');
    left: 50%;
    transform: translateX(-50%);
    width: 147px;
    height: 124px;
    bottom: 51px;
}

.image.skillaz {
    background-image: url('./images/skillaz.png');
    width: 539px;
    height: 306px;
    left: calc(50% + 20px);
    transform: translateX(-50%);
    bottom: -20px;
}

.image.skillaz-secondary {
    background-image: url('./images/skillaz_secondary.png');
    width: 368px;
    height: 215px;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
}

.image.pfp {
    background-image: url('./images/pfp.png');
    width: 262px;
    height: 260px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -20px;
}

.image.hrspace {
    background-image: url('./images/hrspace.png');
    width: 208px;
    height: 143px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 40px;
}

.image.hrspace-secondary {
    background-image: url('./images/hrspace_secondary.png');
    width: 507px;
    height: 218px;
    right: 18px;
    bottom: -36px;
}

.image.efficiency {
    background-image: url('./images/efficiency.png');
    width: 470px;
    height: 266px;
    left: calc(50% - 46px);
    transform: translateX(-50%);
    bottom: -48px;
}

.image.talantix {
    background-image: url('./images/talantix.png');
    width: 227px;
    height: 235px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -36px;
}

.image.live-in-company {
    background-image: url('./images/live_in_company.png');
    width: 402px;
    height: 401px;
    left: calc(50% - 58px);
    transform: translateX(-50%);
    bottom: -220px;
}

.image.it-project {
    background-image: url('./images/it_project.png');
    width: 500px;
    height: 230px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -52px;
}

.image.responses {
    background-image: url('./images/responses.png');
    width: 211px;
    height: 212px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -20px;
}

.image.brand-page,
.image.brand-vacancy {
    background-image: url('./images/brand_vacancy.png');
    width: 160px;
    height: 116px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 52px;
}

.image.brand-snippet {
    background-image: url('./images/brand_snippet.png');
    width: 280px;
    height: 272px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -94px;
}

.image.dreamjob {
    background-image: url('./images/dreamjob.png');
    width: 227px;
    height: 156px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 6px;
}

.image.branding {
    background-image: url('./images/branding.png');
    width: 258px;
    height: 270px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -40px;
}

.image.hr-space-kz {
    background-image: url('./images/hrspace_kz.png');
    width: 378px;
    height: 270px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -40px;
}

.image.search {
    background-image: url('./images/search.png');
    width: 414px;
    height: 245px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2px;
}

.image.vacancy-standard-plus {
    background-image: url('./images/vacancy_standard_plus.png');
    width: 217px;
    height: 146px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;
}

.image.vacancy-premium {
    background-image: url('./images/vacancy_premium.png');
    width: 222px;
    height: 146px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;
}

.image.hh-stats {
    background-image: url('./images/hh_stats.png');
    width: 462px;
    height: 325px;
    left: calc(50% - 38px);
    transform: translateX(-50%);
    bottom: -50px;
}

.image.turbo-response {
    background-image: url('./images/turbo_response.png');
    width: 300px;
    height: 220px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -46px;
}

.image.kak-dela {
    background-image: url('./images/kakdela.png');
    width: 372px;
    height: 329px;
    left: calc(50% - 30px);
    transform: translateX(-50%);
    bottom: -70px;
}

.image.hr-brand {
    background-image: url('./images/hrbrand.png');
    width: 263px;
    height: 241px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -16px;
}

.image.hr-brand-conference {
    background-image: url('./images/hrbrand_conference.png');
    width: 317px;
    height: 317px;
    left: calc(50% + 32px);
    transform: translateX(-50%);
    bottom: -44px;
}

.image.vacancy-advice {
    background-image: url('./images/vacancy_advice.png');
    width: 447px;
    height: 323px;
    left: calc(50% - 42px);
    transform: translateX(-50%);
    bottom: -132px;
}

.image.company-of-day {
    background-image: url('./images/company_of_day.png');
    width: 223px;
    height: 139px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
}

.image.vacancy {
    background-image: url('./images/vacancy.png');
    width: 444px;
    height: 437px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -160px;
}

.image.vacancy-types-zp {
    background-image: url('./images/vacancy_types_zp.png');
    width: 258px;
    height: 186px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 12px;
}

.image.bundles-zp {
    background-image: url('./images/bundles_zp.png');
    width: 252px;
    height: 175px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 16px;
}

.image.base-zp {
    background-image: url('./images/base_zp.png');
    width: 347px;
    height: 167px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
}
