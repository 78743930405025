@import '@hh.ru/magritte-ui/breakpoints';

.container {
    width: 100%;
    margin: 0;
    overflow: hidden;
    display: none;
    transition: height 0.25s ease-out;
    grid-column: 1 / 3;

    .screen-gt-s({
        grid-column: 1 / 4;
    });

    .screen-gt-m({
        grid-column: 1 / 5;
    });
}

.container-visible {
    display: block;
}

.vacancies-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;

    .screen-gt-s({
        grid-template-columns: 1fr 1fr 1fr;
    });

    // Вписываем лого пока не сделан https://jira.hh.ru/browse/PORTFOLIO-26844
    img {
        object-fit: contain;
    }
}

.companies-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px 20px;

    .screen-gt-s({
        grid-template-columns: 1fr 1fr 1fr;
    });

    .screen-gt-m({
        grid-template-columns: 1fr 1fr 1fr 1fr;
    });

    // Вписываем лого пока не сделан https://jira.hh.ru/browse/PORTFOLIO-26844
    img {
        object-fit: contain;
    }
}

.companies-banner {
    grid-row: 1 / 4;
}
