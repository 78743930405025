.text {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 12px;
}

.card-container {
    position: relative;
    height: 228px;
}

.card-content {
    height: 100%;
    display: flex;
    flex-direction: column;
}
