@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/rounded-corner-base';
@import '@hh.ru/magritte-ui/breakpoints';

.counter-wrapper {
    background: @color-blue-10;
}

.counter-wrapper_rounded {
    border-radius: @rounded-corner-default;
}

.counter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 12px;
    padding: 16px;
}

.counter-content {
    padding: 2px 0;
    max-width: 378px;
}

.card-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}

.card-main {
    display: flex;
    gap: 12px;
    flex-grow: 1;
    justify-content: space-between;

    @media @screen-magritte-gt-s {
        flex-grow: 0;
        justify-content: flex-start;
    }
}

.card-fire {
    order: 2;

    @media @screen-magritte-gt-s {
        order: 1;
    }
}

.card-texts {
    order: 1;

    @media @screen-magritte-gt-s {
        order: 2;
    }
}

.fire {
    background: top / contain no-repeat url('./assets/fire.svg');
    width: 40px;
    height: 40px;
}

.card-link {
    flex-shrink: 0;
}
