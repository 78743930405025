@import '@hh.ru/magritte-ui/breakpoints';

@gap: 24px;
@gap-xs: 12px;
@card-height: 80px;
@card-radius: 16px;

.container {
    display: flex;
    flex-direction: column;
    gap: @gap-xs;

    .screen-gt-s({
        display: grid;
        grid-auto-flow: column;
        grid-column-gap: @gap;
        grid-template-columns: 1fr 2fr;
        align-items: flex-start;
    });

    .screen-gt-m({
        grid-template-columns: 1fr 3fr;
    });
}

.container-stack {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.wrapper {
    gap: @gap-xs;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-auto-flow: column dense;

    .screen-gt-xs({
        grid-template-columns: 1fr;
        grid-template-rows: unset;
        grid-auto-flow: unset;
    });

    .screen-gt-s({
        gap: @gap;
        grid-template-columns: repeat(2, 1fr);
    });
    .screen-gt-m({
        grid-template-columns: repeat(3, 1fr);
    });
}

.wrapper-stack {
    display: flex;
    width: 100%;
    gap: 12px;
    flex-direction: column;
}

.wrapper-rest {
    display: flex;
    flex-direction: column;
    gap: @gap-xs;
    .screen-gt-s({
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    });

    .screen-gt-s({
        gap: @gap;
        grid-template-columns: repeat(3, 1fr);
    });

    .screen-gt-m({
        grid-template-columns: repeat(4, 1fr);
    });
}

.wrapper-vertical {
    display: flex;
    gap: @gap-xs;
    flex-direction: column;
}

.banner-column {
    display: flex;
    flex-direction: column;
    gap: @gap-xs;

    .screen-gt-s({
        gap: @gap;
    });
}

.banner-wrapper {
    height: calc(@card-height * 2 + @gap-xs);
    border-radius: @card-radius;
    overflow: hidden;
    display: flex;
    align-items: center;

    .screen-gt-s({
        height: calc(@card-height * 2 + @gap);
    });

    // tempexp_PORTFOLIO-26478_start
    img {
        object-fit: cover;
    }

    > div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    // tempexp_PORTFOLIO-26478_end
}

.item {
    width: 272px;
    height: @card-height;

    .screen-gt-xs({
        width: 100%;
    });

    // tempexp_PORTFOLIO-26478_start
    img {
        object-fit: contain;
    }

    // tempexp_PORTFOLIO-26478_end
}

.wrapper-stack .item {
    width: 100%;
}
