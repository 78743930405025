@import '@hh.ru/magritte-ui/breakpoints';

// todo PORTFOLIO-26478: завязаться на переменную отступа из magritte
@layout-padding: 16px;

.wrapper {
    display: flex;
    overflow: auto;
    margin: 0 -@layout-padding;
    padding: 0 @layout-padding;
    .screen-gt-xs({
        display: block;
        overflow: initial;
        margin: 0;
        padding: 0;
    });
}
