@import '@hh.ru/magritte-ui/breakpoints';

.card-wrapper {
    width: 100%;
    height: 330px;

    @media @screen-magritte-gt-s {
        width: 290px;
    }
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.text {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 12px;
}

.phones {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
