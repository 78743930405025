@import '@hh.ru/magritte-ui/breakpoints';

.hidden {
    display: none;
}

.separate-line {
    display: block;
}

.separate-line-on-s {
    display: block;

    .screen-gt-s({
        display: initial;
    });
}

.wide-container {
    display: none;

    .screen-gt-s({
        display: initial;
    });
}

.narrow-container {
    .screen-gt-s({
        display: none;
    });
}
