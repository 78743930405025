@import '@hh.ru/magritte-ui/breakpoints';

.title {
    font-size: 32px;
    font-weight: 900;
    line-height: 40px;
    letter-spacing: -0.56px;
    margin: 0;
    padding: 0;

    .screen-gt-m({
      font-size: 40px;
      line-height: 48px;
      letter-spacing: -0.7px;
    });
}
