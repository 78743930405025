@import '@hh.ru/magritte-ui/breakpoints';

.blog-card-wrapper {
    display: flex;
    height: 100%;
    min-height: 430px;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.blog-card-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.spacer {
    flex-grow: 1;
}
