@import '@hh.ru/magritte-ui/base-variables';

.actions-container-wide {
    position: relative;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    z-index: 1;
    float: right;
    display: flex;
    flex-direction: row-reverse;
    gap: @magritte-static-space-300;
}

.actions-container-narrow {
    position: relative;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    z-index: 1;
    float: right;
    display: flex;
    flex-direction: column;
    gap: @magritte-static-space-150;
}
