@import '~bloko/common/styles/spacing-base';
@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';

.workflow-status-container {
    display: flex;
    align-items: center;
    padding: @spacing-base * 2 @spacing-base * 4;
}

.workflow-status-text_responded {
    color: @color-gray-50;
}

.workflow-status-text_invited {
    color: @color-green-60;
}

.workflow-status-text_discarded {
    color: @color-orange-60;
}

.workflow-status-redesign {
    display: flex;
    align-items: center;
}

.response-button-redesign-container {
    @media @screen-lt-s {
        flex: 1;
        max-width: 190px;
    }
}
