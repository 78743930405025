.image {
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
}

.card1 {
    margin-bottom: 24px;
}

.card1 .image {
    background-image: url('./images/bird2.png');
    bottom: -4px;
    left: 12px;
    width: 74px;
    height: 99px;
    transform: rotateY(180deg);
}

.card2 .image {
    background-image: url('./images/rabbit-hearts.png');
    bottom: -13px;
    right: -19px;
    width: 128px;
    height: 73px;
}

.card3 {
    margin-bottom: 64px;
}

.card3 .image {
    background-image: url('./images/smile2.png');
    bottom: 29px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 62px;
}

.card4 {
    margin-bottom: 40px;
}

.card4 .image {
    background-image: url('./images/bird1.png');
    bottom: -2px;
    right: -34px;
    width: 118px;
    height: 102px;
}

.card5 {
    margin-bottom: 40px;
}

.card5 .image {
    background-image: url('./images/smile1.png');
    transform: rotate(17deg);
    bottom: 21px;
    left: 45px;
    width: 72px;
    height: 72px;
}

.card6 .image {
    background-image: url('./images/multiple-smiles.png');
    height: 204px;
    width: 160px;
    left: 50%;
    transform: translateX(-50%);
    top: 8px;
}

.card7 {
    margin-bottom: 60px;
}

.card7 .image {
    background-image: url('./images/cat.png');
    width: 144px;
    height: 136px;
    right: 16px;
    bottom: -22px;
}

.card8 {
    margin-top: 40px;
}

.card8 .image {
    background-image: url('./images/smile-heart1.png');
    width: 130px;
    height: 80px;
    left: 50%;
    transform: translateX(-50%);
    top: 12px;
}

.card9 .image {
    background-image: url('./images/rabbit-heart.png');
    width: 122px;
    height: 73px;
    right: -19px;
    bottom: -13px;
}

.card10 {
    margin-bottom: 24px;
}

.card10 .image {
    background-image: url('./images/bird1.png');
    width: 118px;
    height: 102px;
    left: -23px;
    bottom: -21px;
    transform: rotateY(180deg);
}

.card11 .image {
    background-image: url('./images/smile1.png');
    width: 72px;
    height: 72px;
    right: 30px;
    bottom: 2px;
}

.card12 {
    margin-bottom: 24px;
}

.card12 .image {
    background-image: url('./images/smile-heart2.png');
    width: 131px;
    height: 94px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -20px;
}

.card13 .image {
    background-image: url('./images/bird3.png');
    width: 110px;
    height: 99px;
    left: -12px;
    top: -16px;
}

.card14 .image {
    background-image: url('./images/bird2.png');
    width: 74px;
    height: 99px;
    right: 12px;
    bottom: -4px;
}
