@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/text-base';
@import '@hh.ru/magritte-ui/tokens';

.container {
    width: 48px;
    height: 48px;
    position: relative;
}

.content-wrapper {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    margin: 6px;
}

.content {
    margin: auto;
    overflow: hidden;
    color: @color-blue-60;
}

.circle-background {
    stroke: @color-gray-20;
}

.circle-background_redesign {
    stroke: @magritte-color-background-accent-secondary;
}

.circle-progress {
    stroke: @color-blue-60;
    transition: stroke-dashoffset 0.4s ease-in-out;
}

.circle-progress_redesign {
    stroke: @magritte-color-stroke-accent;
}
