.icon {
    width: 24px;
    height: 24px;
}

.clickme {
    background-image: url('icons/clickme.svg');
}

.clickme.inactive {
    background-image: url('icons/clickme-inactive.svg');
}

.skillaz {
    background-image: url('icons/skillaz.svg');
}

.skillaz.inactive {
    background-image: url('icons/skillaz-inactive.svg');
}

.hrlink {
    width: 48px;
    height: 18px;
    background-image: url('icons/hrlink.svg');
}

.hrlink.inactive {
    background-image: url('icons/hrlink-inactive.svg');
}
