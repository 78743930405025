@import '@hh.ru/magritte-ui/tokens';

.content-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.qr-code,
.qr-code-container {
    width: 140px;
    height: 140px;
}

.card-container {
    height: 100%;
    min-height: 282px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.button-container {
    padding: @magritte-static-size-300;
}

.card-flipper-container {
    height: 100%;
    position: relative;
    perspective: 500px;
}

.card-flipper {
    position: absolute;
    width: 100%;
    min-height: 210px;
    height: 100%;
    top: 0;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.card-flipper-flipped {
    transform: rotateY(180deg);
    transition: transform 0.5s;
}

.card-front,
.card-back {
    padding: @magritte-static-size-300;
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-radius: @magritte-static-size-600;
    backface-visibility: hidden;
    background-color: @magritte-color-background-primary;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-back {
    transform: rotateY(180deg);
}
