.container {
    padding: 24px 0;
}

.item {
    padding: 0 24px;
}

.item-row {
    display: flex;
    justify-content: space-between;
    gap: 8px;
}
