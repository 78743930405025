@import '@hh.ru/magritte-ui/breakpoints';

.wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    align-items: stretch;

    .screen-gt-xs({
        flex-direction: row;
        align-items: center;
    });

    .screen-gt-m({
        flex-direction: column;
        align-items: start;
    });
}

.header {
    position: relative;
    margin: 0 auto;
    width: 315px;

    .screen-gt-xs({
        margin: 0;
        width: 230px;
    });

    .screen-gt-s({
        width: 360px;
    });

    .screen-gt-m({
        width: auto;
    });
}

.text {
    width: 250px;
    padding: 12px 0 0 12px;

    .screen-gt-s({
        width: auto;
    });
}

.image-xs {
    position: absolute;
    top: 12px;
    left: 0;
    background: url('images/employer-rating-teaser-mobile.svg?v=24092024') no-repeat;
    width: 100%;
    height: 146px;

    .screen-gt-xs({
        display: none;
    });
}

.image {
    margin: 12px;
    align-self: end;

    .screen-gt-xs({
        background: url('images/employer-rating-teaser-s.svg?v=24092024') no-repeat;
        width: 187px;
        height: 146px;
    });

    .screen-gt-s({
        background: url('images/employer-rating-teaser-m.svg?v=24092024') no-repeat;
        width: 449px;
        height: 146px;
    });

    .screen-gt-m({
        margin: 0 12px;
        background: url('images/employer-rating-teaser-large.svg?v=24092024') no-repeat;
        width: 344px;
        height: 226px;
    });
}

.button {
    width: 100%;

    .screen-gt-xs({
        width: 236px;
    });

    .screen-gt-s( {
        width: 256px;
    });

    .screen-gt-m({
        width: 100%;
    });
}

.container {
    position: relative;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 410px;
}

.description-wrapper {
    max-width: 360px;
}

.illustration {
    width: 219px;
    height: 286px;
    background: url('images/illustration.png');
    background-size: cover;
    margin-bottom: -100px;
}
